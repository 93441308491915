import React from "react";
import styled from "styled-components";
import theme from "../../theme/theme.json";
import Aligner from "./Aligner";
import Owl from "./Owl";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const Card = ({
  padding,
  name,
  initials,
  position,
  level,
  year,
  institute,
  project,
  link,
  picture,
  generate,
}) => {
  return (
    <>
      {generate && (
        <StyledCard to={link} cPad={padding}>
          <Aligner align="center" direction="row">
            <StyledImage image={picture} />
          </Aligner>
          <Aligner direction="row" justify="flex-start">
            <Owl margin="0.35rem">
              <TeamName>{name}</TeamName>
              <Position>{position}</Position>
              <Project>{project}</Project>
            </Owl>
          </Aligner>
        </StyledCard>
      )}
      {!generate && (
        <AlumCard to={link} cPad={padding}>
          <Aligner align="center" direction="row">
            {picture && <StyledImage image={picture} />}
            {!picture && (
              <BadgeContainer>
                <InitialBadge>{initials}</InitialBadge>
              </BadgeContainer>
            )}
          </Aligner>
          <Aligner direction="row" justify="flex-start">
            <Owl margin="0.35rem">
              <TeamName>{name}</TeamName>

              {level === "Other" && <AlumSub>{institute}</AlumSub>}
              {level !== "Other" && (
                <>
                  <AlumSub>
                    {level} ({year}), {institute}
                  </AlumSub>
                </>
              )}
              <Project>{project}</Project>
            </Owl>
          </Aligner>
        </AlumCard>
      )}
    </>
  );
};

export default Card;

const StyledCard = styled((props) => <Link {...props} />)`
  padding: ${(props) => props.cPad || "20px"};
  border-radius: 20px;
  background-color: ${theme.col.bw.w};
  border: ${theme.col.grey[300]} solid 1px;
  transition: all 0.3s;
  display: grid;
  min-height: 180px;
  line-height: 1.2;
  grid-template-columns: 6fr 10fr;
  grid-gap: 20px;
  text-decoration: none;
  position: relative;
  & :hover {
    border: ${theme.col.primary.light} solid 1px;
    opacity: 1;
  }
  & ::after {
    content: "+ Full Profile";
    position: absolute;
    width: 30%;
    font-size: 10px;
    font-weight: 600;
    left: 15px;
    bottom: 4px;
  }
  @media (max-width: 645px) {
    grid-template-columns: 2fr 5fr;
    min-height: max-content;
    & ::after {
      right: 15px;
      text-align: right;
      left: unset;
      bottom: 10px;
    }
  }
`;
const TeamName = styled.span`
  display: block;
  font-weight: bold;
  color: ${theme.col.bw.b};
  width: 100%;
  /* font-weight: bold; */
`;

const Position = styled.p`
  color: ${theme.col.bluegrey[600]};
  font-weight: bold;
  font-size: 14px;
  font-size: ${theme.fs.sm};
`;

const Project = styled.p`
  font-size: ${theme.fs.sm};
`;

const StyledImage = styled((props) => <GatsbyImage {...props} />)`
  border-radius: 9999px;
`;

const AlumCard = styled.div`
  padding: ${(props) => props.cPad || "20px"};
  border-radius: 20px;
  background-color: ${theme.col.bw.w};
  border: ${theme.col.grey[300]} solid 1px;
  transition: all 0.3s;
  display: grid;
  min-height: 180px;
  line-height: 1.2;
  grid-template-columns: 6fr 10fr;
  grid-gap: 20px;
  text-decoration: none;
  position: relative;
  & :hover {
    border: ${theme.col.primary.dark} solid 1px;
    opacity: 1;
  }
  @media (max-width: 645px) {
    grid-template-columns: 2fr 5fr;
    min-height: max-content;
    & ::after {
      right: 15px;
      text-align: right;
      left: unset;
      bottom: 10px;
    }
  }
`;

const BadgeContainer = styled.div`
  max-width: 137px;
  max-height: 137px;
  width: 100%;
  margin: auto;
`;

const InitialBadge = styled.div`
  background: ${theme.col.primary.low};
  color: white;
  font-weight: bold;
  font-size: 1.5rem;
  width: 100%;
  height: 100%;
  border-radius: 9999px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30%;
`;

const AlumSub = styled.span`
  display: block;
  color: ${theme.col.bluegrey[600]};
  font-weight: bold;
  font-size: 14px;
  font-size: ${theme.fs.sm};
`;
