import React from "react";
import styled from "styled-components";
import theme from "../../theme/theme.json";

const Card = ({ children, padding }) => {
  return <StyledCard cPad={padding}>{children}</StyledCard>;
};

export default Card;

const StyledCard = styled.div`
  padding: ${(props) => props.cPad || "1.5em"};
  border-radius: 20px;
  background-color: ${theme.col.bw.w};
  color: ${theme.col.bw.b};
  border: ${theme.col.grey[300]} solid 1px;
  transition: all 0.3s;
  & :hover {
    border: ${theme.col.primary.light} solid 1px;
  }
`;
