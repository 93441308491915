import React, { useState } from "react";
import styled from "styled-components";
import theme from "../../theme/theme.json";
import Owl from "../comp-layouts/Owl";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import Button from "../Button";
import * as Icon from "react-feather";

const PostSection = ({ posts }) => {
  const [limit, setLimit] = useState(4);
  const [showLoadMore, setShowLoadMore] = useState(true);
  const increment = 4;

  const increaseLimit = () => {
    setLimit(limit + increment);
  };

  let visiblePosts = posts.slice(0, limit || posts.length);
  return (
    <>
      {!!visiblePosts.length && (
        <PostGrid>
          {visiblePosts.map(({ node }) => (
            <PostGridItem key={node.id}>
              <Owl margin="1rem">
                <PostLink to={`/${node.fields.collection}${node.fields.slug}`}>
                  <PostImage>
                    <GatsbyImage
                      image={getImage(node.frontmatter.image)}
                      alt={node.frontmatter.alt}
                    />
                    <PostDate>
                      <Icon.Calendar color={theme.col.primary.low} />
                      <span style={{ margin: "0 0 0 10px" }}>
                        {node.frontmatter.date}
                      </span>
                    </PostDate>
                  </PostImage>
                  <PostTitle>{node.frontmatter.title}</PostTitle>
                </PostLink>

                <PostDescription>
                  {node.frontmatter.description}{" "}
                  <PostLink
                    to={`/${node.fields.collection}${node.fields.slug}`}
                  >
                    Full Article »
                  </PostLink>
                </PostDescription>
              </Owl>
            </PostGridItem>
          ))}
        </PostGrid>
      )}
      {showLoadMore && visiblePosts.length < posts.length && (
        <ButtonPlacer>
          <Button onClick={increaseLimit}>Load More</Button>
        </ButtonPlacer>
      )}
    </>
  );
};

export default PostSection;

const ButtonPlacer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;
`;

const PostGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 20px;
  padding-bottom: 60px;
`;

const PostGridItem = styled.div`
  position: relative;
`;

const PostTitle = styled.h4`
  color: ${theme.col.bw.b};
`;

const PostImage = styled.div`
  position: relative;
`;

const PostDate = styled.time`
  font-weight: bold;
  display: flex;
  background: ${theme.col.bw.w};
  color: ${theme.col.primary.low};
  margin-right: 15px;
  padding: 4px 8px;
  border-radius: 8px;
  font-size: ${theme.fs.sm};
  position: absolute;
  z-index: 2;
  left: 10px;
  bottom: 20px;
`;

const PostDescription = styled.p`
  margin-bottom: 20px;
`;

const PostLink = styled((props) => <Link {...props} />)`
  display: inline-block;
  font-weight: bold;
  font-size: 14px;
  text-decoration: none;
`;
