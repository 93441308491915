import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import Layout from "../components/global/layout";
import SEO from "../components/global/seo";
import styled from "styled-components";
import LoadMorePostGrid from "../components/comp-layouts/LoadMorePostGrid";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Container from "../components/comp-layouts/Container";
import Owl from "../components/comp-layouts/Owl";
import Card from "../components/comp-layouts/Card";
import TeamCard from "../components/comp-layouts/TeamCard";
import Grid from "../components/comp-layouts/Grid";

const getData = graphql`
  {
    allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___coredetails___order] }
      filter: { frontmatter: { core: { eq: true } } }
    ) {
      edges {
        node {
          fields {
            slug
            collection
          }
          frontmatter {
            core
            generate
            title
            description
            coredetails {
              order
              position
            }
            alt
            image {
              publicURL
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`;

const News = () => {
  const data = useStaticQuery(getData);
  const coreTeam = data.allMarkdownRemark.edges;
  return (
    <Layout>
      <SEO
        title="Core Team - Marine Plastic & Seabird Researchers"
        description="Our core team of researchers and collaborators work together to further our research in multiple fields, including the impacts of plastic pollution on wildlife and marine environments."
        slug="core-team"
      />
      <Container mt="60px" mb="20px">
        <Owl>
          <h1>Core Team</h1>
          <p>
            Our core team of researchers and collaborators work together to
            further our research in multiple fields, including the impacts of
            plastic pollution on wildlife and marine environments.
          </p>
        </Owl>
      </Container>
      <Container width="1024px" mb="80px">
        <div style={{ height: 40 }} />
        <CardGrid>
          {coreTeam.map(({ node }) => (
            <TeamCard
              name={node.frontmatter.title}
              picture={node.frontmatter.image.childImageSharp.gatsbyImageData}
              position={node.frontmatter.coredetails.position}
              link={node.fields.slug}
              generate={node.frontmatter.generate}
            />
          ))}
        </CardGrid>
      </Container>
    </Layout>
  );
};

export default News;

const CardGrid = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  grid-auto-rows: 1fr;
`;
